import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Blob from '@UI/blob';
import Button from '@UI/button';
import FormattedMessage from '@Lib/i18n';
import styles from './static-login-page-styles.scss';
import fetchJSON from '../../universal/fetch';
import config from '../../universal/config';
import { redirect, getWindow } from '@Lib/global';
import LoginLayout from '../login-layout';

const ERROR_MESSAGES = {
  ErrPasswordRequired: 'login.staticLogin.error.passwordRequired',
  ErrEmailRequired: 'login.staticLogin.error.emailRequired',
  ErrAuthenticationFailed: 'login.staticLogin.error.unauthorized',
  ErrSSORequired: 'login.staticLogin.error.ssoRequired',
  ErrTooManyRequests: 'login.staticLogin.error.tooManyRequests',
  ErrBadRequest: 'login.staticLogin.error.defaultError',
  ErrInternalServerError: 'login.staticLogin.error.defaultError',
  ErrFetchError: 'login.staticLogin.error.defaultError',
};

const NOTICE_MESSAGES = {
  NoticePasswordResetSent: 'login.staticLogin.notice.passwordResetSent',
};

const StaticLoginPage = () => {
  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState(null);
  const [noticeCode, setNoticeCode] = useState(null);

  const doFetch = async ({ email, password, remember }) => {
    try {
      const loginUrl = `${config.get().AutographBaseURL}/login`;
      const { redirect_url: redirectUrl } = await fetchJSON(loginUrl, {
        credentials: 'include',
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ email, password, remember }),
      });
      redirect(redirectUrl);
    } catch (error) {
      const { type = 'ErrFetchError' } = error;
      setErrorCode(type);
      setLoading(false);
    }
  };

  const onSubmit = (e) => {
    setLoading(true);
    e.preventDefault();
    const email = e.target.elements.email.value;
    const password = e.target.elements.password.value;
    const remember = e.target.elements.remember.checked;

    if (!email) {
      setErrorCode('ErrEmailRequired');
      setLoading(false);
      return;
    }

    if (!password) {
      setErrorCode('ErrPasswordRequired');
      setLoading(false);
      return;
    }

    doFetch({ email, password, remember });
  };

  useEffect(() => {
    const queryString = getWindow().location.search;
    const urlParams = new URLSearchParams(queryString);
    const urlNotice = urlParams.get('notice');

    if (urlNotice) {
      setNoticeCode(urlNotice);
    }
  }, [setNoticeCode]);

  return (
    <LoginLayout
      title="Sign in to Geckoboard"
      heading="Sign in to Geckoboard"
      footer={<Footer />}
    >
      {errorCode && (
        <div className={styles.errorMessage}>
          <FormattedMessage
            id={
              ERROR_MESSAGES[errorCode] ||
              'login.staticLogin.error.defaultError'
            }
          />
        </div>
      )}
      {noticeCode && (
        <div className={styles.noticeMessage}>
          <FormattedMessage id={NOTICE_MESSAGES[noticeCode]} />
        </div>
      )}
      <form onSubmit={onSubmit}>
        <div className={styles.formField}>
          <input
            type="email"
            placeholder="Email address"
            name="email"
            aria-label="email"
            className={styles.emailInput}
            autoFocus
            required
          />
          <div className={styles.blob}>
            <Blob version={Blob.VERSIONS.FIRST} />
          </div>
        </div>
        <div className={styles.formField}>
          <input
            type="password"
            placeholder="Password"
            name="password"
            aria-label="password"
            className={styles.passwordInput}
            required
          />
          <div className={styles.passwordBlob}>
            <Blob version={Blob.VERSIONS.SECOND} />
          </div>
        </div>
        <div className={styles.extraFields}>
          <div className={styles.rememberMe}>
            <label htmlFor="remember">
              <input
                id="remember"
                type="checkbox"
                name="remember"
                aria-label="Remember me"
              />
              <span className="checkbox" />
              Remember me
            </label>
          </div>
          <div className={styles.forgotLink}>
            <Link to="/password_resets">Forgot your password?</Link>
          </div>
        </div>
        <Button
          disabled={loading}
          loading={loading}
          stretched
          type={Button.TYPES.SUBMIT}
        >
          <FormattedMessage id="login.staticLogin.submitButton" />
        </Button>
      </form>
      <div className={styles.ssoSection}>
        <Link to="/sso" className="sso-link">
          Sign in with SSO
        </Link>
      </div>
    </LoginLayout>
  );
};

function Footer() {
  return (
    <p>
      Don’t have an account?{' '}
      <a href="https://www.geckoboard.com/try-geckoboard/">Sign up for free</a>
    </p>
  );
}

export default StaticLoginPage;
